import createTsdApi from "../../../client-api/createTsdApi";
import {
  User,
  UserQueryParams,
  UserCreateParams,
  UserUpdateParams,
} from "../../../entities/user";
import tsdFetch, { TsdSearchParam } from "../fetch";

const userApi = createTsdApi<
  User,
  UserQueryParams,
  UserCreateParams,
  UserUpdateParams
>("users", (searchParamsObject) => {
  let searchParams: TsdSearchParam<UserQueryParams>[] = [];

  if (typeof searchParamsObject.isDeveloper !== "undefined") {
    searchParams.push({
      key: "isDeveloper",
      value: searchParamsObject.isDeveloper ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: searchParamsObject.isActive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isInactive !== "undefined") {
    searchParams.push({
      key: "isInactive",
      value: searchParamsObject.isInactive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: searchParamsObject.isDeleted ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.email !== "undefined") {
    searchParams.push({ key: "email", value: searchParamsObject.email });
  }

  if (typeof searchParamsObject.username !== "undefined") {
    searchParams.push({ key: "username", value: searchParamsObject.username });
  }

  if (typeof searchParamsObject.contactIds !== "undefined") {
    searchParamsObject.contactIds.forEach((id) =>
      searchParams.push({
        key: "contactIds",
        value: String(id),
      })
    );
  }

  if (typeof searchParamsObject.staffId !== "undefined") {
    searchParams.push({
      key: "staffId",
      value: String(searchParamsObject.staffId),
    });
  }

  if (typeof searchParamsObject.status !== "undefined") {
    searchParamsObject.status.forEach((status) => {
      searchParams.push({
        key: "status",
        value: String(status),
      });
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  return searchParams;
});

const sendPasswordResetEmail = (id: number) => {
  return tsdFetch(
    `/api/v1/users/${encodeURIComponent(id)}/send-password-reset-email`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
};

export default { ...userApi, sendPasswordResetEmail };
