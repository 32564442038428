import createTsdApi from "../../createTsdApi";
import {
  StaffHoliday,
  StaffHolidayCreateParams,
  StaffHolidayQueryParams,
  StaffHolidayUpdateParams,
} from "../../../entities/StaffHoliday/staffHoliday";
import { TsdSearchParam } from "../fetch";

const staffHolidayApi = createTsdApi<
  StaffHoliday,
  StaffHolidayQueryParams,
  StaffHolidayCreateParams,
  StaffHolidayUpdateParams
>("staff-holidays", (searchParamsObject) => {
  let searchParams: TsdSearchParam<StaffHolidayQueryParams>[] = [];

  if (typeof searchParamsObject.startDateLte !== "undefined") {
    searchParams.push({
      key: "startDateLte",
      value: searchParamsObject.startDateLte,
    });
  }

  if (typeof searchParamsObject.startDateGte !== "undefined") {
    searchParams.push({
      key: "startDateGte",
      value: searchParamsObject.startDateGte,
    });
  }

  if (typeof searchParamsObject.finalDateLte !== "undefined") {
    searchParams.push({
      key: "finalDateLte",
      value: searchParamsObject.finalDateLte,
    });
  }

  if (typeof searchParamsObject.finalDateGte !== "undefined") {
    searchParams.push({
      key: "finalDateGte",
      value: searchParamsObject.finalDateGte,
    });
  }

  if (typeof searchParamsObject.staffIds !== "undefined") {
    searchParamsObject.staffIds.forEach((id) =>
      searchParams.push({
        key: "staffIds",
        value: String(id),
      })
    );
  }

  if (typeof searchParamsObject.isApproved !== "undefined") {
    searchParams.push({
      key: "isApproved",
      value: searchParamsObject.isApproved ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isRejected !== "undefined") {
    searchParams.push({
      key: "isRejected",
      value: searchParamsObject.isRejected ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isPending !== "undefined") {
    searchParams.push({
      key: "isPending",
      value: searchParamsObject.isPending ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.sortStartDate !== "undefined") {
    searchParams.push({
      key: "sortStartDate",
      value: searchParamsObject.sortStartDate,
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.isStaffArchived !== "undefined") {
    searchParams.push({
      key: "isStaffArchived",
      value: searchParamsObject.isStaffArchived ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: searchParamsObject.isDeleted ? "1" : "0",
    });
  }

  return searchParams;
});

export default staffHolidayApi;
