import createTsdApi from "../../../client-api/createTsdApi";
import {
  Note,
  NoteCreateParams,
  NoteQueryParams,
  NoteUpdateParams,
} from "../../../entities/note";
import { TsdSearchParam } from "../fetch";

const noteApi = createTsdApi<
  Note,
  NoteQueryParams,
  NoteCreateParams,
  NoteUpdateParams
>("notes", (params) => {
  const search: TsdSearchParam<NoteQueryParams>[] = [];

  if (typeof params.customerIds !== "undefined") {
    params.customerIds.forEach((id) =>
      search.push({ key: "customerIds", value: String(id) })
    );
  }

  if (typeof params.isActive !== "undefined") {
    search.push({ key: "isActive", value: params.isActive ? "1" : "0" });
  }

  if (typeof params.isDeleted !== "undefined") {
    search.push({ key: "isDeleted", value: params.isDeleted ? "1" : "0" });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  return search;
});

export default noteApi;
