import dayjs from "dayjs";
import createTsdApi from "../../../client-api/createTsdApi";
import {
  Discount,
  DiscountCreateParams,
  DiscountQueryParams,
  DiscountUpdateParams,
} from "../../../entities/discount";
import { TsdSearchParam } from "../fetch";

const discountApi = createTsdApi<
  Discount,
  DiscountQueryParams,
  DiscountCreateParams,
  DiscountUpdateParams
>("discounts", (params) => {
  const searchParams: TsdSearchParam<DiscountQueryParams>[] = [];

  if (typeof params.notIds !== "undefined") {
    params.notIds.forEach((id) => {
      searchParams.push({
        key: "notIds",
        value: String(id),
      });
    });
  }

  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      searchParams.push({
        key: "orgIds",
        value: String(id),
      });
    });
  }

  if (typeof params.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: params.isActive ? "1" : "0",
    });
  }

  if (typeof params.isInactive !== "undefined") {
    searchParams.push({
      key: "isInactive",
      value: params.isInactive ? "1" : "0",
    });
  }

  if (typeof params.startDateLte !== "undefined") {
    searchParams.push({
      key: "startDateLte",
      value: dayjs()
        .fromTsdDateFormat(params.startDateLte)
        .format("YYYY-MM-DD"),
    });
  }

  if (typeof params.endDateGte !== "undefined") {
    searchParams.push({
      key: "endDateGte",
      value: dayjs().fromTsdDateFormat(params.endDateGte).format("YYYY-MM-DD"),
    });
  }

  if (typeof params.orderByStartDate !== "undefined") {
    searchParams.push({
      key: "orderByStartDate",
      value: params.orderByStartDate,
    });
  }

  return searchParams;
});

export default discountApi;
