import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierAccount,
  SupplierAccountCreateParams,
  SupplierAccountQueryParams,
  SupplierAccountUpdateParams,
} from "../../../entities/supplierAccount";
import tsdFetch, { ITsdFetch, TsdSearchParam } from "../fetch";

const supplierAccountApi = createTsdApi<
  SupplierAccount,
  SupplierAccountQueryParams,
  SupplierAccountCreateParams,
  SupplierAccountUpdateParams
>("supplier-accounts", (params) => {
  const searchParams: TsdSearchParam<SupplierAccountQueryParams>[] = [];

  if (typeof params.supplierIds !== "undefined") {
    params.supplierIds.forEach((id) => {
      searchParams.push({
        key: "supplierIds",
        value: String(id),
      });
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  if (typeof params.orderByIsDefault !== "undefined") {
    searchParams.push({
      key: "orderByIsDefault",
      value: params.orderByIsDefault,
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({ key: "offset", value: String(params.offset) });
  }

  return searchParams;
});

type DeleteFunc = (id: number) => ITsdFetch<void>;

const deleteOne: DeleteFunc = async (id) => {
  const url = `/api/v1/supplier-accounts/${id}`;
  return tsdFetch(url, {
    method: "DELETE",
  });
};

export default { ...supplierAccountApi, delete: deleteOne };
