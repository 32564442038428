import createTsdApi from "../../createTsdApi";
import {
  Event,
  EventCreateParams,
  EventQueryParams,
  EventUpdateParams,
} from "../../../entities/Event/event";
import { TsdSearchParam } from "../fetch";

const eventApi = createTsdApi<
  Event,
  EventQueryParams,
  EventCreateParams,
  EventUpdateParams
>("event", (searchParamsObject) => {
  let searchParams: TsdSearchParam<EventQueryParams>[] = [];

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.timestampDateGte !== "undefined") {
    searchParams.push({
      key: "timestampDateGte",
      value: searchParamsObject.timestampDateGte,
    });
  }

  if (typeof searchParamsObject.timestampDateLte !== "undefined") {
    searchParams.push({
      key: "timestampDateLte",
      value: searchParamsObject.timestampDateLte,
    });
  }

  return searchParams;
});

export default eventApi;
