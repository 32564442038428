import createTsdApi from "../../../client-api/createTsdApi";
import {
  OrderItemSize,
  OrderItemSizeCreateParams,
  OrderItemSizeQueryParams,
  OrderItemSizeUpdateParams,
} from "../../../entities/OrderItemSize/orderItemSize";
import { TsdSearchParam } from "../fetch";

const orderItemSizeApi = createTsdApi<
  OrderItemSize,
  OrderItemSizeQueryParams,
  OrderItemSizeCreateParams,
  OrderItemSizeUpdateParams
>("order-item-sizes", (params) => {
  const search: TsdSearchParam<OrderItemSizeQueryParams>[] = [];

  if (typeof params.ids !== "undefined") {
    params.ids.forEach((id) => {
      search.push({ key: "ids", value: String(id) });
    });
  }

  if (typeof params.orderItemIds !== "undefined") {
    params.orderItemIds.forEach((orderItemId) => {
      search.push({ key: "orderItemIds", value: String(orderItemId) });
    });
  }

  if (typeof params.productSizeOptionIds !== "undefined") {
    params.productSizeOptionIds.forEach((productSizeOptionId) => {
      search.push({
        key: "productSizeOptionIds",
        value: String(productSizeOptionId),
      });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  return search;
});

export default orderItemSizeApi;
