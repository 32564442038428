import createTsdApi from "../../../client-api/createTsdApi";
import {
  StatementProductsCategories,
  StatementProductsCategoriesCreateParams,
  StatementProductsCategoriesQueryParams,
  StatementProductsCategoriesUpdateParams,
} from "../../../entities/StatementProductsCategories/statementProductsCategories";
import tsdFetch, { ITsdFetch, TsdSearchParam } from "../fetch";

const statementProductsCategoriesApi = createTsdApi<
  StatementProductsCategories,
  StatementProductsCategoriesQueryParams,
  StatementProductsCategoriesCreateParams,
  StatementProductsCategoriesUpdateParams
>("statement-products-categories", (params) => {
  const search: TsdSearchParam<StatementProductsCategoriesQueryParams>[] = [];

  if (typeof params.productIds !== "undefined") {
    params.productIds.forEach((id) => {
      search.push({
        key: "productIds",
        value: String(id),
      });
    });
  }

  if (typeof params.categoryIds !== "undefined") {
    params.categoryIds.forEach((id) => {
      search.push({
        key: "categoryIds",
        value: String(id),
      });
    });
  }

  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      search.push({
        key: "orgIds",
        value: String(id),
      });
    });
  }

  if (typeof params.notCategoryIds !== "undefined") {
    params.notCategoryIds.forEach((id) => {
      search.push({
        key: "notCategoryIds",
        value: String(id),
      });
    });
  }

  if (typeof params.notProductIds !== "undefined") {
    params.notProductIds.forEach((id) => {
      search.push({
        key: "notProductIds",
        value: String(id),
      });
    });
  }

  return search;
});

type DeleteParams = {
  productId: number;
  categoryId: number;
  orgId: number;
};

type DeleteFunc = ({
  productId,
  categoryId,
  orgId,
}: DeleteParams) => ITsdFetch<StatementProductsCategories[]>;

const deleteOne: DeleteFunc = async ({ productId, categoryId, orgId }) => {
  const url = `/api/v1/statement-products-categories`;

  let searchParams: TsdSearchParam<DeleteParams>[] = [];

  searchParams.push({
    key: "productId",
    value: String(productId),
  });

  searchParams.push({
    key: "categoryId",
    value: String(categoryId),
  });

  searchParams.push({
    key: "orgId",
    value: String(orgId),
  });

  return tsdFetch(url, { method: "DELETE" }, searchParams);
};

export default { ...statementProductsCategoriesApi, delete: deleteOne };
